@import '@/styles/mixins.scss';

.wrapper {
    padding: 40px 0;

    text-align: center;
}

.title {
    @include headline-2;
    text-align: center;
}

.table {
    border-collapse: collapse;
    margin: 32px auto 0;

    tr {
        background: white;

        &:first-child td:first-child {
            color: var(--colors-gray-dark-70);
        }
    }

    tbody tr:nth-child(odd) {
        background: var(--colors-gray-light-95);
    }

    td {
        padding: 16px 20px;
    }

    th {
        @include headline-6;
        color: var(--colors-primary);
        text-align: center;

        width: 240px;
        padding: 0 16px 20px;
    }

    tbody td:first-child {
        @include subtitle-M;
        color: var(--colors-gray-dark-30);
    }

    &.more-columns {
        th {
            width: 180px;
            padding: 0 16px 20px;
        }
    }
}

.price {
    @include caption-L;
    color: var(--colors-primary-dark-20);
    background: var(--colors-primary-light-90);

    padding: 4px 8px;
    border-radius: 4px;
}

.icon {
    width: 24px;
    height: 24px;
}

.link {
    @include cta-secondary;
    @include flex-center;
    gap: 8px;

    color: var(--colors-primary);

    text-decoration: none;
    margin-top: 24px;
}

.link-icon {
    width: 16px;
    height: 16px;
}

@media screen and (max-width: 1280px) {
    .wrapper {
        padding: 40px 0 60px;
    }
}

@media screen and (max-width: 960px) {
    .table {
        th {
            width: 228px;
        }

        &.more-columns th {
            width: 160px;
        }
    }
}

@media screen and (max-width: 764px) {
    .table {
        th {
            width: 33%;
            padding: 0 6px 20px;

            &:first-child {
                min-width: 180px;
            }
        }

        &.more-columns th {
            width: 20%;
        }
    }
}

@media screen and (max-width: 524px) {
    .pricing-wrap {
        padding: 0 12px;
    }

    .table {
        th {
            padding: 0 0 20px;
            width: 33%;

            &:first-child {
                min-width: 140px;
                width: 45%;
            }
        }

        &.more-columns {
            th {
                padding: 0 0 8px;
                width: 20%;

                &:first-child {
                    min-width: 110px;
                    width: 35%;
                }
            }

            td:first-child {
                padding: 12px 8px;
            }

            .price-cell {
                padding: 0;
            }
        }
    }
}
